import makeStyles from "@mui/styles/makeStyles";
import theme from "../../../theme";

const useStyles = makeStyles({
  codeTagStyle: {
    wordWrap: 'break-word'
  }
});

const styles = {
  container: {
    width: '100%',
    margin: '10px 0px',
    padding: '8px'
  },
  languageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  languageText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 550,
  },
  selectStyle: {
    width: '20%',
    color: theme.palette.text.primary,
    fontSize: '12px',
    marginLeft: '16px',
    height: '24px'
  },
  menuItem: {
    color: theme.palette.text.primary,
    fontSize: '12px',
  },
  codeStyle: {
    fontSize: '13px'
  },
};

export default { styles, useStyles };
