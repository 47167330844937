const styles = {
  container: {
    height: '20px',
    width: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    margin: '0px 0px 0px 6px',
    padding: '8px 12px'
  },
  toReviewContainer: {
    backgroundColor: '#3e8aff4d',
    border: '1px solid #3e8aff4d',
  },
  toReviewText: {
    color: '#3E8AFF',
    fontWeight: 'bold',
    fontSize: '13px'
  },
  toValidateContainer: {
    backgroundColor: '#ffb9464d',
    border: '1px solid #ffb9464d',
  },
  toValidateText: {
    color: '#F5B140',
    fontWeight: 'bold',
    fontSize: '13px'
  },
  toExportContainer: {
    backgroundColor: '#2ed47a4d',
    border: '1px solid #2ed47a4d',
  },
  toExportText: {
    color: '#3CB573',
    fontWeight: 'bold',
    fontSize: '13px'
  },
  exportedContainer: {
    backgroundColor: '#2a7e504d',
    border: '1px solid #2a7e504d',
  },
  exportedText: {
    color: '#2A7E50',
    fontWeight: 'bold',
    fontSize: '13px'
  },
  rejectedContainer: {
    backgroundColor: '#ef6c004d',
    border: '1px solid #ef6c004d',
  },
  rejectedText: {
    color: '#EF6C00',
    fontWeight: 'bold',
    fontSize: '13px'
  },
  archivedContainer: {
    backgroundColor: '#4e342e4d',
    border: '1px solid #4e342e4d',
  },
  archivedText: {
    color: '#4E342E',
    fontWeight: 'bold',
    fontSize: '13px'
  },
  splitRequestContainer: {
    backgroundColor: '#02aef74d',
    border: '1px solid #02aef74d',
  },
  splitRequestText: {
    color: '#02aef7',
    fontWeight: 'bold',
    fontSize: '13px'
  },
  erroredContainer: {
    backgroundColor: '#eb57574d',
    border: '1px solid #eb57574d',
  },
  erroredText: {
    color: '#EB5757',
    fontWeight: 'bold',
    fontSize: '13px'
  }
};

export default styles;
