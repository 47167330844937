import React from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import {
    FiberManualRecord as DotIcon,
    FiberManualRecordOutlined as OutlinedDotIcon,
    Square as SquareIcon,
    ArrowForwardIos as NavigateNextIcon,
    ArrowBackIos as NavigatePrevIcon
  } from '@mui/icons-material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { SectionProps } from '../../../interfaces';
import styles from './style';

const Authentication = ({ sectionProps }: { sectionProps: SectionProps }) => {
    const { title, setSelectedTitle } = sectionProps;

    const handlePrev = () => {
        setSelectedTitle('gettingStarted');
        window.history.replaceState(null, `${window?.config?.brand} - API`, "/dashboard/gettingStarted");
    };

    const handleNext = () => {
        setSelectedTitle('documentStatuses');
        window.history.replaceState(null, `${window?.config?.brand} - API`, "/dashboard/documentStatuses");
    };

    return (
        <Grid item md={10} sx={styles.container}>
            <Grid sx={styles.contentContainer}>
                <Typography sx={styles.title}>
                    {title}
                </Typography>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      The {window?.config?.brand} API implements JSON Web Token (JWT) based bearer token authentication,
                      a widely accepted industry-standard method for securely representing claims
                      between two parties.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      To authenticate API requests, you must acquire a JWT bearer token. To obtain
                      an access token, register for a {window?.config?.brand} account and enable API integration by
                      visiting our website. Once enabled, you may obtain an access token (token pair).
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.subContentItalic}>
                      The token pair comprises the access token and refresh token pairs.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      This token (access-token) must be sent in the “Authorization” header when
                      making requests to protected resources. You can view your tokens in the {window?.config?.brand} app.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      Example:
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.subContent}>
                      Bearer token in the Authorization header
                    </Typography>
                </Box>
                <Box sx={styles.codeSnippetContainer}>
                    <SquareIcon sx={styles.boxIcon} />
                    <SyntaxHighlighter customStyle={styles.codeStyle} showLineNumbers showInlineLineNumbers language="cURL" style={docco} wrapLongLines={true}>
                      {'-H "Authorization: bearer {token}"'}
                    </SyntaxHighlighter>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.subContent}>
                      Upload document end-point with bearer token in the headers
                    </Typography>
                </Box>
                <Box sx={styles.codeSnippetContainer}>
                    <SquareIcon sx={styles.boxIcon} />
                    <SyntaxHighlighter customStyle={styles.codeStyle} showLineNumbers showInlineLineNumbers language="cURL" style={docco} wrapLongLines={true}>
                      {`curl -X POST https://app-api.${
                          window?.config?.brand_domain
                        }/v1/documents \n-F datafile=@path/to/file \n-H "Authorization: bearer {token}"`}
                    </SyntaxHighlighter>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      To obtain the token pair required to access the {window?.config?.brand} API, you may exchange
                      your refresh token using the provided API. The access token, which has a
                      validity period of one hour, grants temporary authorization to perform API
                      requests, while the refresh token is valid for a longer period of 6 months
                      (180 days). We strongly recommend storing the token pair in a secure manner
                      within your database. Prior to making API requests, please verify the access
                      token&apos;s validity and obtain a new token pair by exchanging the refresh token
                      if the access token has expired.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      Here is the request to get the token pair from refresh-token:
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <SyntaxHighlighter customStyle={styles.codeStyle} showLineNumbers showInlineLineNumbers language="cURL" style={docco} >
                      {`curl -X GET https://auth.${
                          window?.config?.brand_domain
                        }/v1/auth/tokens/refresh-token/<refresh-token> \n -H "Content-Type":"application/json"`}
                    </SyntaxHighlighter>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      And it returns:
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <SyntaxHighlighter customStyle={styles.codeStyle} showLineNumbers showInlineLineNumbers language="cURL" style={docco} wrapLongLines={true}>
                      {'{\n "accessToken": "string",\n "company": "string",\n "email": "string",\n "expiresIn": "integer",\n "refreshToken": "string"\n}'}
                    </SyntaxHighlighter>
                </Box>
            </Grid>
            <Box sx={styles.footerContainer}>
                <Typography sx={styles.footerText}>
                  If you encounter any issues or have inquiries about our API, please do not hesitate
                  to reach out to our support team at <Link href={`mailto:${window?.config?.support_email}`}>
                    {window?.config?.support_email}</Link>. Our team is available to
                  assist you in maximizing the benefits of our API and ensuring a seamless integration
                  into your application. 
                </Typography>
            </Box>
            <Grid sx={styles.navBtnContainer}>
                <Button
                    variant="contained"
                    sx={styles.prevBtn}
                    color="primary"
                    onClick={handlePrev}
                >
                    <NavigatePrevIcon sx={styles.prevIcon} />
                    Previous
                </Button>
                <Button
                    variant="contained"
                    sx={styles.nextBtn}
                    color="primary"
                    onClick={handleNext}
                >
                    Next
                    <NavigateNextIcon sx={styles.nextIcon} />
                </Button>
            </Grid>
        </Grid>
    );
}

export default Authentication;
