import React from 'react';
import {
  Route, Switch, BrowserRouter, Redirect
} from 'react-router-dom';
import Dashboard from './Dashboard/containers/Dashboard/Dashboard';
import Error404 from './Shared/components/Error404/Error404';

function Routes() {
  return(
      <React.Fragment key="switch-routes">
        <BrowserRouter>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route path="/dashboard" component={Dashboard} />
            <Route exact path="/404" component={Error404} />
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
}

export default Routes;
