import theme from '../../../theme';

const styles = {
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  container: {
    flexDirection: 'column',
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: 550,
    marginBottom: '8px'
  },
  barColorPrimary: {
    backgroundColor: '#3E8AFF'
  },
  colorPrimary: {
    backgroundColor: '#AECDFC'
  }
};

export default styles;
