export const THEMES = {
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK'
};

let domain = 'procys.com';
const host = window.location.hostname;
domain = !window.location.hostname.includes('docs') ? 'procys.com' : host.replace('docs.','');

declare global {
  interface Window { config: {
      brand: string,
      brand_url: string,
      brand_domain: string,
      support_email: string,
    }
  }
}

window.config = window.config || {};

export const procysHome = window?.config?.brand_url || `https://www.${domain}/`;
export const procysDashboard = `https://login.${domain}/`;
export const apiDoc = `https://provider.${domain}/v1/api-doc/${domain}`;
// export const apiDoc = `http://localhost:9091/v1/api-doc/${domain}`;

export const axiosHeader = {
    headers: {
      'Content-Type': 'application/json',
    }
  };

export const endpointSections = {
  auth: "Authentication",
  documents: "Documents",
  exports: "Exports",
  invoices: "Invoices",
  subscription: "Subscription",
  supplier: "Supplier",
  stats: "Stats",
  fields: "Fields",
  customisation: "Customisation",
  company: "Company",
  integrations: "Integrations",
};
