import { CssBaseline } from '@mui/material';
import React, { forwardRef, LegacyRef } from 'react';
import { Helmet } from 'react-helmet';
import { PageProps } from '../../../interfaces';

const Page = forwardRef(({
  title,
  style,
  children,
  ...rest
} : PageProps, ref: LegacyRef<HTMLDivElement>) => (
  <div
    ref={ref}
    style={style}
    {...rest}
  >
    <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <CssBaseline />
        {children}
    </>
  </div>
));

Page.displayName = 'Page';

export default Page;
