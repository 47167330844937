import theme from "../../../theme";

const styles = {
  container: {
    width: '100%',
    margin: '10px 0px',
    padding: '8px'
  },
  titleText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 550,
  },
  codeStyle: {
    fontSize: '13px'
  }
};

export default styles;
