import React from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import {
    FiberManualRecord as DotIcon,
    FiberManualRecordOutlined as OutlinedDotIcon,
    ArrowForwardIos as NavigateNextIcon,
    ArrowBackIos as NavigatePrevIcon
  } from '@mui/icons-material';
import { SectionProps } from '../../../interfaces';
import styles from './style';

const GettingStarted = ({ sectionProps }: { sectionProps: SectionProps }) => {
    const { title, setSelectedTitle } = sectionProps;

    const handlePrev = () => {
        setSelectedTitle('introduction');
        window.history.replaceState(null, `${window?.config?.brand} - API`, "/dashboard/introduction");
    };

    const handleNext = () => {
        setSelectedTitle('authentication');
        window.history.replaceState(null, `${window?.config?.brand} - API`, "/dashboard/authentication");
    };

    return (
        <Grid item md={10} sx={styles.container}>
            <Grid sx={styles.contentContainer}>
                <Typography sx={styles.title}>
                    {title}
                </Typography>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                        To begin working with the {window?.config?.brand} API, you will first need to obtain
                        an access token (token pair) that grants access to our API. To obtain an
                        access token, simply register for a {window?.config?.brand} account at our website and
                        enable API integration. This will allow you to obtain an access token
                        (token pair) required to make API calls.
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.subContent}>
                        The token pair comprises the access token and refresh token pairs.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                        With the access token, you can initiate API calls to access {window?.config?.brand} data and
                        services. Our API adheres to REST principles, utilizing standard HTTP
                        requests and responses to communicate with the server. To execute an API call,
                        you will need to send an HTTP request to the API endpoint, including any
                        mandatory parameters and authentication information.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                        For detailed documentation on our API, including a comprehensive list of
                        available endpoints, parameters, and responses, please refer to our API
                        reference section titled &ldquo;{window?.config?.brand} API.&rdquo; We also offer code examples in various
                        programming languages to assist you in integrating our API into your application.
                    </Typography>
                </Box>
            </Grid>
            <Box sx={styles.footerContainer}>
                <Typography sx={styles.footerText}>
                    If you encounter any issues or have inquiries about our API, please do not
                    hesitate to reach out to our support team at <Link href={`mailto:${window?.config?.support_email}`}>
                        {window?.config?.support_email}</Link>. Our team
                    is available to assist you in maximizing the benefits of our API and ensuring
                    a seamless integration into your application.
                </Typography>
            </Box>
            <Grid sx={styles.navBtnContainer}>
                <Button
                    variant="contained"
                    sx={styles.prevBtn}
                    color="primary"
                    onClick={handlePrev}
                >
                    <NavigatePrevIcon sx={styles.prevIcon} />
                    Previous
                </Button>
                <Button
                    variant="contained"
                    sx={styles.nextBtn}
                    color="primary"
                    onClick={handleNext}
                >
                    Next
                    <NavigateNextIcon sx={styles.nextIcon} />
                </Button>
            </Grid>
        </Grid>
    );
}

export default GettingStarted;
