import React, { useState, useEffect } from 'react';
import Axios, { AxiosError } from 'axios';
import TopBar from '../../components/TopBar/TopBar';
import Page from '../../../Shared/components/Page/Page';
import { Box, Grid } from '@mui/material';
import LeftPanel from '../../components/LeftPanel/LeftPanel';
import EndpointDetails from '../../../EndPoint/components/EndpointDetails/EndpointDetails';
import { EndPointProps, RootProps } from '../../../interfaces';
import IntroductionContent from '../../../Introduction/components/Introduction/IntroductionContent';
import { apiDoc, axiosHeader } from '../../../config';
import LoadingIndicator from '../../../Shared/components/LoadingIndicator/LoadingIndicator';
import root from '../../SampleData/api-doc';
import classes from './style';
import GettingStarted from '../../../Introduction/components/GettingStarted/GettingStarted';
import Authentication from '../../../Introduction/components/Authentication/Authentication';
import DocumentStatuses from '../../../Introduction/components/DocumentStatuses/DocumentStatuses';
import APIResponses from '../../../Introduction/components/APIResponses/APIResponses';

function Dashboard() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<RootProps>(root);
    const [selectedTitle, setSelectedTitle] = useState<string>(Object.keys(root)[0]);
    const [selectedEndpoint, setSelectedEndpoint] = useState<EndPointProps>(root.procysApi.endpoints.auth[0]);
    const [defaultEndpoint, setDefaultEndpoint] = useState<EndPointProps>(root.procysApi.endpoints.auth[0]);
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleEndpointSelect = (endpoint: EndPointProps) => {
        setSelectedEndpoint(endpoint);
        window.history.replaceState(null, `${window?.config?.brand} - API`, `/dashboard/${endpoint.id}`);
    };

    const getData = async () => {
        setLoading(true);
        try {
            const response = await Axios.get(
              apiDoc,
              axiosHeader
            );
            if (response.status === 200) {
              const parsed: {root: RootProps} = JSON.parse(response.data.docs);
              setData(parsed.root);
              const url = new URL(window.location.href);
              const pathVar = url.pathname.replace('/dashboard', '');
              const { auth, documents, exports, subscription, supplier, stats, fields, customisation, company, integrations } = parsed.root.procysApi.endpoints;
              const endpoint = [
                  ...auth,
                  ...documents,
                  ...exports,
                  ...subscription,
                  ...supplier,
                  ...stats,
                  ...fields,
                  ...customisation,
                  ...company,
                  ...integrations
              ].find((e: EndPointProps) => e.id === pathVar.slice(1));
              const defaultSelectedEndpoint = 
                Object.values(parsed.root.procysApi.endpoints)
                  .find((i) => i.some((j: EndPointProps) => j.availableViaAPI))
                  .find((o: EndPointProps) => o.availableViaAPI);
              setDefaultEndpoint(defaultSelectedEndpoint);
              if (pathVar && endpoint) {
                setSelectedTitle('procysApi');
                setSelectedEndpoint(endpoint);
              } else if (pathVar) {
                setSelectedTitle(pathVar.slice(1));
                setSelectedEndpoint(defaultSelectedEndpoint);
              } else {
                setSelectedTitle(Object.keys(parsed.root)[0]);
                setSelectedEndpoint(defaultSelectedEndpoint);
              }
              setLoading(false);
            } else {
              setLoading(false);
            }
          } catch (error) {
            const err = error as AxiosError;
            if (err.response && err.response.data) {
              //
            } else {
              //
            }
            setLoading(false);
          }
    }

    useEffect(() => {
        getData();
    }, []);

    if (loading) {
        return <LoadingIndicator text="Data is loading..." />
    }
    
    return (
        <Page title={`${window?.config?.brand} - API`} style={classes.root}>
            <TopBar handleDrawerToggle={handleDrawerToggle} />
            <Box sx={classes.wrapper}>
                <Box sx={classes.contentContainer}>
                    <Box sx={classes.content} className="hide-scroll">
                        <Grid sx={classes.container}>
                            <LeftPanel
                                leftPanelProps={{
                                    root: data,
                                    selectedEndpoint,
                                    selectedTitle,
                                    mobileOpen,
                                    handleDrawerToggle,
                                    setSelectedTitle,
                                    handleEndpointSelect
                                }} />
                            {selectedTitle === 'introduction' &&
                              (<IntroductionContent
                                  sectionProps={{ title: data.introduction.title, selectedEndpoint, setSelectedTitle, handleEndpointSelect}}
                                />
                              )}
                            {selectedTitle === 'gettingStarted' &&
                              (<GettingStarted sectionProps={{ title: data.gettingStarted.title, selectedEndpoint, setSelectedTitle, handleEndpointSelect}} />)}
                            {selectedTitle === 'authentication' &&
                              (<Authentication sectionProps={{ title: data.authentication.title, selectedEndpoint, setSelectedTitle, handleEndpointSelect}} />)}
                            {selectedTitle === 'documentStatuses' &&
                              (<DocumentStatuses
                                  sectionProps={{ title: data.documentStatuses.title, selectedEndpoint, setSelectedTitle, handleEndpointSelect}}
                                />
                              )}
                            {selectedTitle === 'apiResponses' &&
                              (<APIResponses
                                  sectionProps={{ title: data.apiResponses.title, selectedEndpoint: defaultEndpoint, setSelectedTitle, handleEndpointSelect}}
                                />
                              )}
                            {selectedTitle === 'procysApi' && (<EndpointDetails endpoint={selectedEndpoint} setSelectedTitle={setSelectedTitle} />)}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Page>
    );
}

export default Dashboard;
