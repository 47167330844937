import theme from "../../../theme";

const styles = {
  container: {
    paddingTop: '10px',
    paddingLeft: '36px',
    paddingBottom: '36px',
    paddingRight: '36px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  contentContainer: {
    width: '50%',
    minWidth: '500px',
    padding: '128px 0px',
  },
  navBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    justifyContent: 'flex-end'
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  bulletContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: '20px 0px'
  },
  content: {
    color: theme.palette.text.primary,
    fontSize: '15px',
    marginLeft: '8px',
    fontWeight: 500
  },
  dotIcon: {
    color: theme.palette.text.primary,
    fontSize: '10px',
    marginTop: '6px'
  },
  nextBtn: {
    width: '96px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '4px 4px 4px 12px'
  },
  nextIcon: {
    fontSize: '15px',
    fontWeight: 'bold',
    marginLeft: '6px'
  }
};

export default styles;
