import React from 'react';
import { Grid, Typography } from '@mui/material';
import { StatusContainerProps } from '../../../interfaces';
import styles from './style';

const StatusContainer = ({ statusProps }: { statusProps: StatusContainerProps }) => {
    const { status } = statusProps;

    switch (status) {
      case 'toReview': {
          return (<Grid sx={{...styles.container, ...styles.toReviewContainer}}>
                      <Typography sx={styles.toReviewText}>To Review</Typography>
                  </Grid>);
      }
      case 'toValidate': {
          return (<Grid sx={{...styles.container, ...styles.toValidateContainer}}>
                      <Typography sx={styles.toValidateText}>To Validate</Typography>
                  </Grid>);
      }
      case 'toExport': {
          return (<Grid sx={{...styles.container, ...styles.toExportContainer}}>
                      <Typography sx={styles.toExportText}>To Export</Typography>
                  </Grid>);
      }
      case 'exported': {
          return (<Grid sx={{...styles.container, ...styles.exportedContainer}}>
                      <Typography sx={styles.exportedText}>Exported</Typography>
                  </Grid>);
      }
      case 'rejected': {
          return (<Grid sx={{...styles.container, ...styles.rejectedContainer}}>
                      <Typography sx={styles.rejectedText}>Rejected</Typography>
                  </Grid>);
      }
      case 'archived': {
          return (<Grid sx={{...styles.container, ...styles.archivedContainer}}>
                      <Typography sx={styles.archivedText}>Archived</Typography>
                  </Grid>);
      }
      case 'splitRequest': {
          return (<Grid sx={{...styles.container, ...styles.splitRequestContainer}}>
                      <Typography sx={styles.splitRequestText}>Split Request</Typography>
                  </Grid>);
      }
      case 'errored': {
          return (<Grid sx={{...styles.container, ...styles.erroredContainer}}>
                      <Typography sx={styles.erroredText}>Errored</Typography>
                  </Grid>);
      }
      default: {
          return (<Grid sx={styles.container}></Grid>);
      }
  }
}

export default StatusContainer;
