import React from 'react';
import { Grid, Typography } from '@mui/material';
import styles from './style';

const MethodType = (props: { method: string }) => {
    const { method } = props;
    switch (method) {
        case 'POST': {
            return (<Grid sx={{...styles.container, ...styles.postContainer}}>
                        <Typography sx={styles.postText}>post</Typography>
                    </Grid>);
        }
        case 'GET': {
            return (<Grid sx={{...styles.container, ...styles.getContainer}}>
                        <Typography sx={styles.getText}>get</Typography>
                    </Grid>);
        }
        case 'PUT': {
            return (<Grid sx={{...styles.container, ...styles.putContainer}}>
                        <Typography sx={styles.putText}>put</Typography>
                    </Grid>);
        }
        case 'DELETE': {
            return (<Grid sx={{...styles.container, ...styles.deleteContainer}}>
                        <Typography sx={styles.deleteText}>delete</Typography>
                    </Grid>);
        }
        case 'PATCH': {
            return (<Grid sx={{...styles.container, ...styles.patchContainer}}>
                        <Typography sx={styles.patchText}>patch</Typography>
                    </Grid>);
        }
        default: {
            return (<Grid sx={styles.container}></Grid>);
        }
    }
}

export default MethodType;
