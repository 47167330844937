import React, { Dispatch, SetStateAction, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import style from './style';
import { ParameterProps } from '../../../interfaces';

const Parameter = (props: { item: ParameterProps, setSelectedTitle: Dispatch<SetStateAction<string>> }) => {
    const { item, setSelectedTitle } = props;
    const { styles, useStyles } = style;
    const [propsExpanded, setPropsExpanded] = useState<boolean>(false);
    const classes = useStyles();

    const handleOnLinkClick = () => {
        if (item.link !== undefined) {
            setSelectedTitle(item.link);
            window.history.replaceState(null, `${window?.config?.brand} - API`, `/dashboard/${item.link}`);
        }
    };

    const ParameterDetails = (props: { param: ParameterProps, isProp?: boolean }) => (
        <Grid sx={styles.paramContainer}>
            <Grid sx={styles.titleContainer}>
                <Typography sx={props.isProp ? styles.propTitle : styles.title}>{props.param.name}</Typography>
                {props.param.required ? (
                    <Typography sx={styles.required}>REQUIRED</Typography>
                ) : (
                    <Typography sx={styles.optional}>optional</Typography>
                )}
                <Grid sx={styles.typeContainer}>
                    <Typography sx={props.isProp ? styles.propTypeText : styles.typeText}>{props.param.type}</Typography>
                </Grid>
                {props.param.in !== 'body' && (
                    <Grid sx={styles.typeContainer}>
                        <Typography sx={styles.typeText}>{props.param.in}</Typography>
                    </Grid>
                )}
            </Grid>
            <Grid sx={styles.descrContainer}>
                <Typography sx={styles.descr}>{props.param.description}</Typography>
                {props.param.link && (<Typography onClick={handleOnLinkClick} sx={styles.linkDescr}>{props.param.linkDescription}</Typography>)}
            </Grid>
        </Grid>
    );

    return (
        <Grid sx={styles.container}>
            <ParameterDetails param={item} />
            {item.properties && item.properties.length > 0 && (
                <Accordion
                    classes={{ root: classes.accordionGroup, expanded: classes.accordionGroupExpanded }}
                    onChange={() => setPropsExpanded((prev) => !prev)}
                    expanded={propsExpanded}
                >
                    <AccordionSummary
                        classes={{ 
                            root: classes.accordianSummary,
                            content: classes.accordianSummaryContent,
                            expanded: classes.accordianSummaryExpanded
                        }}
                    >
                        {propsExpanded
                            ? <Grid sx={styles.propTitleContainer}>
                                <RemoveIcon sx={styles.propTitleIcon} />
                                <Typography sx={styles.propTitle}>Hide properties</Typography>
                            </Grid>
                            : <Grid sx={styles.propTitleContainer}>
                                <AddIcon sx={styles.propTitleIcon} />
                                <Typography sx={styles.propTitle}>Show properties</Typography>
                            </Grid>}
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetails }}>
                        <Grid>
                            {item.properties.map((param, i) => (
                                <Grid key={param.name} sx={i + 1 === item.properties?.length ? styles.propParamContainerLast : styles.propParamContainer}>
                                    <ParameterDetails param={param} isProp />
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            )}
        </Grid>
    );
}

export default Parameter;
