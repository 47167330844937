import makeStyles from "@mui/styles/makeStyles";
import theme from "../../../theme";

const useStyles = makeStyles({
  searchBarRoot: {
    height: '36px',
  },
  searchBarInput: {
    '&::placeholder': {
      color: '#90A0B7',
      fontSize: '14px',
      lineHeight: '21px'
    }
  },
  toolBarRoot: {
    minHeight: '60px',
  },
});

const styles = {
  root: {
    zIndex: 100,
    boxShadow: 'none',
    backgroundColor: 'white',
    filter: 'drop-shadow(0px 2px 4px rgba(51, 77, 110, 0.1))'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '60px',
    width: '100%',
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    padding: '8px'
  },
  logo: {
    maxWidth: '120px',
    maxHeight: '60px'
  },
  searchBar: {
    flex: 1,
    marginRight: '24px',
    marginLeft: '24px',
  },
  queryField: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    border: '0px solid rgba(194, 207, 224, 1)',
    borderRadius: 4,
    margin: '0px',
    width: '100%',
    minWidth: '120px',
    display: 'none'
  },
  getStartedContainer: {
    textDecoration: 'none'
  },
  getStartedBtn: {
    fontSize: '14px',
    fontWeight: '600px',
    marginTop: '0px',
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    height: '36px',
    minWidth: '218px',
    textTransform: 'none',
    textDecoration: 'none'
  },
};

export default { styles, useStyles };
