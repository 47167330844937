import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import {
    FiberManualRecord as DotIcon,
    ArrowForwardIos as NavigateNextIcon
  } from '@mui/icons-material';
import { SectionProps } from '../../../interfaces';
import styles from './style';

const IntroductionContent = ({ sectionProps }: { sectionProps: SectionProps }) => {
    const { title, setSelectedTitle } = sectionProps;

    const handleNext = () => {
        setSelectedTitle('gettingStarted');
        window.history.replaceState(null, `${window?.config?.brand} - API`, "/dashboard/gettingStarted");
    };

    return (
        <Grid item md={10} sx={styles.container}>
            <Grid sx={styles.contentContainer}>
                <Typography sx={styles.title}>
                    {title}
                </Typography>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                        Welcome to the {window?.config?.brand} API!
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                        {window?.config?.brand} API allows you to easily access and manipulate {window?.config?.brand} data and services.
                        This guide will help you get started with our API and provide you with the
                        necessary information to make your first API call.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                        {window?.config?.brand} API has predictable resource-oriented URLs, accepts form-encoded or
                        JSON-encoded request bodies, returns JSON-encoded responses, and uses standard
                        HTTP response codes and authentication.
                    </Typography>
                </Box>
            </Grid>
            <Grid sx={styles.navBtnContainer}>
                <Button
                    variant="contained"
                    sx={styles.nextBtn}
                    color="primary"
                    onClick={handleNext}
                >
                    Next
                    <NavigateNextIcon sx={styles.nextIcon} />
                </Button>
            </Grid>
        </Grid>
    );
}

export default IntroductionContent;
