import React, { useState } from "react";
import PropTypes from 'prop-types';
import { AppBar, Box, Button, Grid, IconButton, InputAdornment, SvgIcon, TextField, Toolbar, Tooltip, useMediaQuery } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import style from './style';
import { procysDashboard, procysHome } from "../../../config";
import { TopBarProps } from "../../../interfaces";

const TopBar = ({handleDrawerToggle}: TopBarProps) => {
  const { styles, useStyles } = style;
  const classes = useStyles();
  const maxWidth = useMediaQuery('(min-width: 630px)');

  const [query, setQuery] = useState('');

  let domain = 'procys.com';
  const host = window.location.hostname;
  domain = !window.location.hostname.includes('docs') ? 'procys.com' : host.replace('docs.','');

  const procysLogo = (
    <a style={styles.logoContainer} href={procysHome} target="_blank" rel="noopener noreferrer">
      <img src={`https://provider.${domain}/v1/brands/logo/${domain}`} alt="" style={styles.logo} />
    </a>
  );

  const drawerToggle = (
    <IconButton
      color="primary"
      aria-label="open drawer"
      edge="start"
      onClick={handleDrawerToggle}
      sx={{ mr: 2, display: { md: 'none' } }}
    >
      <MenuIcon />
    </IconButton>
  );

  const searchBar = (
    <Grid sx={styles.searchBar}>
      <Tooltip title="">
        <TextField
          sx={styles.queryField}
          onChange={(e) => setQuery(e.target.value)}
          placeholder=""
          value={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{ fill: '#C2CFE0' }}
                >
                  <SearchIcon style={{ color: '#C2CFE0' }} />
                </SvgIcon>
              </InputAdornment>
            ),
            classes: {
              root: classes.searchBarRoot,
              input: classes.searchBarInput
            }
          }}
        />
      </Tooltip>
    </Grid>
  );

  const getStartedBtn = (
    <a href={procysDashboard} style={styles.getStartedContainer}>
      <Button
        color="primary"
        variant="contained"
        sx={styles.getStartedBtn}
      >
        Get Started
      </Button>
    </a>
  );

  return (
    <AppBar sx={styles.root}>
      <Toolbar sx={styles.toolbar} classes={{ root: classes.toolBarRoot }}>
        <Box sx={styles.navContainer}>
          {maxWidth ? <>
            {drawerToggle}
            {procysLogo}
            {searchBar}
            {getStartedBtn}
          </> : <>
            {drawerToggle}
            {procysLogo}
            {getStartedBtn}
          </>}
        </Box>
      </Toolbar>
    </AppBar>);
};

TopBar.propTypes = {
  handleDrawerToggle: PropTypes.func,
};

export default TopBar;
