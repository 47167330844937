import React from 'react';
import { Grid, Typography } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styles from './style';
import { ResponseBodyProps } from '../../../interfaces';

const ResponseBody = (props: { responseBody: ResponseBodyProps }) => {
    const { responseBody } = props;

    return (
        <Grid sx={styles.container}>
            <Typography sx={styles.titleText}>Response example</Typography>
            <SyntaxHighlighter customStyle={styles.codeStyle} language="JSON" style={docco} wrapLongLines={true}>
                {JSON.stringify(responseBody.responseObject, null, "  ")}
            </SyntaxHighlighter>
        </Grid>
    );
}

export default ResponseBody;
