/* eslint-disable max-len */
import { RootProps } from "../../interfaces";

const root: RootProps = {
  introduction: {
      title: "Introduction",
  },
  gettingStarted: {
      title: "Getting Started",
  },
  authentication: {
      title: "Authentication"
  },
  documentStatuses: {
      title: "Document Statuses"
  },
  apiResponses: {
      title: "API Responses"
  },
  procysApi: {
      title: "ProcysAPI",
      content: "...",
      endpoints: {
          auth: [
            {
                "id": "getTokenPairFromRefreshToken",
                "title": "Get Token Pair From Refresh Token",
                "availableViaAPI": true,
                "content": "Returns a new pair of access and refresh tokens, given a valid refresh token.",
                "url": "/v1/auth/tokens/refresh-token/{token}",
                "method": "GET",
                "parameters": {
                    "required": [
                        {
                            "name": "token",
                            "type": "string",
                            "in": "path",
                            "required": true,
                            "description": "Refresh Token"
                        }
                    ],
                    "optional": []
                },
                "response": {
                    "description": "The response includes a \"data\" field with an object containing the following properties. \"accessToken\", a string representing the access token generated for the user. \"company\", a string representing the name of the company associated with the user. \"email\", a string representing the email address of the user. \"expiresIn\", an integer representing the number of seconds until the access token expires. In addition, there is a \"refreshToken\" property which is also a string representing the refresh token associated with the user. The \"error\" field includes a \"code\" and \"message\" property if there was an error processing the request. The \"success\" field is a boolean value indicating whether the request was successful.",
                    "example": {
                        "data": {
                            "accessToken": "string",
                            "company": "string",
                            "email": "string",
                            "expiresIn": "integer",
                            "refreshToken": "string"
                        },
                        "error": {
                            "code": "integer",
                            "message": "string"
                        },
                        "success": true
                    }
                },
                "snippets": [
                    {
                        "language": "cURL",
                        "code": "curl -X GET https://auth.procys.com/v1/auth/tokens/refresh-token/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3QucHJvY3lzQGdtYWlsLmNvbSIsInVzZXJuYW1lIjoiIiwiYXBwbGljYXRpb24iD2xfcm9jeXMiLCJjb21wYW55IjoiNTgyNjk1IiwiZ3JhbnQiOiJhY2Nlc3MiLCJleHAiOjE2ODI0ODY3MjV9.Qpep6Mx0BVZUYDjJxZgO2lMeQWyfbiSJIaLQ0cb-MZA\n\t-H \"Content-Type\": \"application/json\""
                    },
                    { 
                        "language": "python",
                        "code": "import requests\n\nr = requests.post(\n\u0020\u0020'https://auth.procys.com/v1/auth/tokens/refresh-token/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3QucHJvY3lzQGdtYWlsLmNvbSIsInVzZXJuYW1lIjoiIiwiYXBwbGljYXRpb24iD2xfcm9jeXMiLCJjb21wYW55IjoiNTgyNjk1IiwiZ3JhbnQiOiJhY2Nlc3MiLCJleHAiOjE2ODI0ODY3MjV9.Qpep6Mx0BVZUYDjJxZgO2lMeQWyfbiSJIaLQ0cb-MZA',\n\u0020\u0020headers = {\n\u0020\u0020\u0020\u0020\"Content-Type\": \"application/json\"\n\u0020\u0020}\n)\n\nreturn r.json()"
                    },
                    { 
                        "language": "php",
                        "code": "<?php\n\u0020\u0020$url = 'https://auth.procys.com/v1/auth/tokens/refresh-token/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3QucHJvY3lzQGdtYWlsLmNvbSIsInVzZXJuYW1lIjoiIiwiYXBwbGljYXRpb24iD2xfcm9jeXMiLCJjb21wYW55IjoiNTgyNjk1IiwiZ3JhbnQiOiJhY2Nlc3MiLCJleHAiOjE2ODI0ODY3MjV9.Qpep6Mx0BVZUYDjJxZgO2lMeQWyfbiSJIaLQ0cb-MZA';\n\n\u0020\u0020$options = array(\n\u0020\u0020\u0020\u0020'http' => array(\n\u0020\u0020\u0020\u0020\u0020'header'  => \"Content-Type: application/json\\r\\n\",\n\u0020\u0020\u0020\u0020\u0020'method'  => 'GET',\n\u0020\u0020\u0020\u0020),\n\u0020\u0020);\n\n\u0020\u0020$context = stream_context_create($options);\n\u0020\u0020$response = file_get_contents($url, false, $context);\n\n\u0020\u0020// The response is in JSON format, decode it to a PHP object\n\u0020\u0020$response_data = json_decode($response);\n?>"
                    },
                    { 
                        "language": "C#",
                        "code": "using (var httpClient = new HttpClient())\n{\n\u0020\u0020try\n\u0020\u0020{\n\u0020\u0020\u0020\u0020var refreshToken = \"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.\neyJlbWFpbCI6InRlc3QucHJvY3lzQGdtYWlsLmNvbSIsInVzZXJuYW1lIjoiIiwiYXBwbGljYXRpb24iD2xfcm9jeXMiLCJjb21wYW55IjoiNTgyNjk1IiwiZ3JhbnQiOiJhY2Nlc3MiLCJleHAiOjE2ODI0ODY3MjV9.\nQpep6Mx0BVZUYDjJxZgO2lMeQWyfbiSJIaLQ0cb-MZA\";\n\n\u0020\u0020\u0020\u0020// Create the request content\n\u0020\u0020\u0020\u0020var content = new StringContent(\"\", System.Text.Encoding.UTF8, \"application/json\");\n\n\u0020\u0020\u0020\u0020// Make the POST request and get the response\n\u0020\u0020\u0020\u0020var response = httpClient.GetAsync($\"https://auth.procys.com/v1/auth/tokens/refresh-token/{refreshToken}\").Result;\n\n\u0020\u0020\u0020\u0020// Read the response content as JSON\n\u0020\u0020\u0020\u0020var responseContent = response.Content.ReadAsStringAsync().Result;\n\n\u0020\u0020\u0020\u0020// Parse the JSON response\n\u0020\u0020\u0020\u0020dynamic jsonResponse = Newtonsoft.Json.JsonConvert.DeserializeObject(responseContent);\n\n\u0020\u0020\u0020\u0020// Do something with the JSON response\n\u0020\u0020\u0020\u0020Console.WriteLine(jsonResponse);\n\u0020\u0020}\n\u0020\u0020catch (Exception ex)\n\u0020\u0020{\n\u0020\u0020\u0020\u0020Console.WriteLine(\"An error occurred: \" + ex.Message);\n\u0020\u0020}\n}"
                    }
                ]
            }
           ],
          documents: [
             {
                id: "getAttachedFiles",
                title: "Get Attached Files",
                content: "You can use this endpoint to retrieve attached documents of an invoice",
                availableViaAPI: true,
                url: "/v1/files/attachments/{name}",
                method: "POST",
                parameters: {
                  required: [],
                  optional: [
                    {
                       name: "name",
                       type: "string",
                       in: "path",
                       description: "Name of the attached file"
                    }
                 ]  
                },
                snippets: [{ code: "curl-XPUT{host}/v1/invoices-H{token}-d{company:company_name,invoiceID:invoice_id}", language: "cURL" }],
                response: {
                  description: "The response body contains the details of the attached document",
                  example: {
                      error: {
                         code: 0,
                         message: "string"
                      },
                      i18n: "string",
                      message: "string",
                      success: "boolean",
                      data: {
                          documentId: "number",
                          date: "date",
                          amount: "number",
                          currency: "string"
                      }
                   }
                }
             }
          ],
          exports: [
            {
                "id": "exportInvoiceEmail",
                "title": "Export Invoice to Email",
                "availableViaAPI": true,
                "content": "This endpoint allows the user to export invoice data in a specified format and send it to one or more email addresses.",
                "url": "/v1/documents/export/email/{type}",
                "method": "POST",
                "contentType": "application/json",
                "parameters": {
                    "required": [
                        {
                            "name": "type",
                            "type": "string",
                            "in": "path",
                            "required": true,
                            "description": "Exporting type is one of [csv, ubl23, ubl21, ubl23_csv], or it could be a specific custom export type"
                        },
                        {
                            "name": "assistantIDS",
                            "type": "array",
                            "in": "body",
                            "required": true,
                            "description": "Invoice assistant IDs list"
                        },
                        {
                            "name": "emails",
                            "type": "array",
                            "in": "body",
                            "required": true,
                            "description": "List of Emails to which the exported results should be delivered"
                        }
                    ],
                    "optional": []
                },
                "response": {
                    "description": "Will include a boolean value indicating whether the request was successful, as well as any relevant messages or errors.",
                    "example": {
                        "error": {
                            "code": "integer",
                            "message": "string"
                        },
                        "message": "string",
                        "success": "boolean"
                    }
                },
                "snippets": [
                    {
                        "language": "cURL",
                        "code": "curl -X POST https://app-api.procys.com/v1/documents/export/email/csv\n\t-d '{\n\t\u0020\u0020\u0020\u0020\"assistantIDS\": ['10001', '10002', '10003', '10004'],\n\t\u0020\u0020\u0020\u0020\"emails\": ['jon.doe@ocr.email', 'peter.smith@ocr.email']\n\t}'\n\t-H \"Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9eyJlbWFpbCI6Impvbi5kb2VAcHJvY3lzLmNvbSIsInVzZXJuYW1lIjoiIiwiYXBwbGljYXRpb24iOiJwcm9jeXMiLCJjb21wYW55IjoiMzIzMjMxMiIsImdyYW50IjoiYWNjZXNzIiwiZXhwIjoxNjgyMTUxODc3fQ-9neUOHETWCS6EuDNGTbNMEqpaoEG4uIPXPv11_Q1UI\"\n\t-H \"Content-Type: application/json"
                    },
                    {
                        "language": "python",
                        "code": "import requests\n\npayload = {\n\u0020\u0020'assistantIDS': ['10001', '10002', '10003', '10004'],\n\u0020\u0020'emails': ['jon.doe@ocr.email', 'peter.smith@ocr.email']\n}\n\nr = requests.post(\n\u0020\u0020'https://app-api.procys.com/v1/documents/export/email/csv',\n\u0020\u0020data=json.dumps(payload),\n\u0020\u0020headers={\n\u0020\u0020\u0020\u0020\"Content-Type\":\"application/json\",\n\u0020\u0020\u0020\u0020\"Authorization\": \"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.\neyJlbWFpbCI6Impvbi5kb2VAcHJvY3lzLmNvbSIsInVzZXJuYW1lIjoiIiwiYXBwbGljYXRpb24iOiJwcm9jeXMiLCJjb21wYW55IjoiMzIzMjMxMiIsImdyYW50IjoiYWNjZXNzIiwiZXhwIjoxNjgyMTUxODc3fQ.\n-9neUOHETWCS6EuDNGTbNMEqpaoEG4uIPXPv11_Q1UI\"\n\u0020\u0020}\n)\n\nreturn r.json()"
                    },
                    {
                        "language": "php",
                        "code": "<?php\n\u0020\u0020$url = 'https://app-api.procys.com/v1/documents/export/email/csv';\n\u0020\u0020$token = \"<token>\";\n\u0020\u0020$data = array(\n\u0020\u0020\u0020\u0020\"assistantIDS\" => array(\"10001\", \"10002\", \"10003\", \"10004\"),\n\u0020\u0020\u0020\u0020\"emails\" => array(\"jon.doe@ocr.email\", \"peter.smith@ocr.email\")\n\u0020\u0020);\n\n\u0020\u0020$headers = array(\n\u0020\u0020\u0020\u0020\"Authorization: Bearer $token\",\n\u0020\u0020\u0020\u0020\"Content-Type: application/json\"\n\u0020\u0020);\n\n\u0020\u0020$options = array(\n\u0020\u0020\u0020\u0020'http' => array(\n\u0020\u0020\u0020\u0020\u0020\u0020'header'  => implode(\"\\r\\n\", $headers),\n\u0020\u0020\u0020\u0020\u0020\u0020'method'  => 'POST',\n\u0020\u0020\u0020\u0020\u0020\u0020'content' => json_encode($data),\n\u0020\u0020\u0020\u0020),\n\u0020\u0020);\n\n\u0020\u0020$context  = stream_context_create($options);\n\u0020\u0020$response = file_get_contents($url, false, $context);\n\n\u0020\u0020// The response is in JSON format, decode it to a PHP object \n\u0020\u0020echo $response\n?>"
                    }
                ]
            },
          ],
          subscription: [
              {
                  id: "verifySubscription",
                  title: "Verify Subscription",
                  content: "The Verify Subscription endpoint takes a \"company\" ID as a path parameter.",
                  availableViaAPI: true,
                  url: "/v1/subscriptions/verify/{company}",
                  method: "GET",
                  parameters: {
                    required: [],
                    optional: [{
                        name: "company",
                        type:"string",
                        in: "path",
                        description: "Company ID"
                    }]
                  },
                  response: {
                      description: "The endpoint returns a JSON response that has three main keys: \"data\", \"error\", and \"success\".",
                      example: {
                          data: {
                              available: "integer"
                            },
                            error: {
                              code: "integer",
                              message: "string"
                            },
                            success: "boolean"
                      }
                  },
                  snippets: [
                      {
                          language: "cURL",
                          code : "curl -X GET {host}/v1/subscriptions/verify/{company} -H \"Authorization: bearer {token}\""
                      },
                      {
                          language: "python",
                          code : "req = requests.get(\n{host}/v1/subscriptions/verify/{company},\n\
                          headers={\n\"Content-Type\":\"application/json\",\n\"Authorization\": \"bearer <access_token>\"\n}\n)\n\nreturn req.json()"
                      }
                  ]
              }
          ],
          supplier: [
              {
                  id: "exportSupplier",
                  title: "Export Suppliers",
                  content: "This endpoint is for exporting supplier information using the HTTP method GET.",
                  availableViaAPI: true,
                  url: "/v1/suppliers/export/{company}",
                  method: "GET",
                  parameters: {
                    required: [
                        {
                            name: "company",
                            type:"string",
                            in: "path",
                            required: true
                        }
                    ],
                    optional: []
                  },
                  response: {
                      description: "The endpoint returns a downloadable file containing the exported supplier information.",
                      example: {
                          file: true
                      }
                  },
                  snippets: [
                      {
                          language: "cURL",
                          code:"curl -X GET {host}/v1/suppliers/export/{company} -d {'company':'<company>'} -H {token}"
                      },
                      {
                          language: "python",
                          code: "payload={'company':'<company>'}\n\nreq = requests.get(\n\
                              {host}/v1/suppliers/export/{company},\ndata=payload\nheaders={\n\
                                  \"Content-Type\":\"application/json\",\n\"Authorization\": \"bearer <access_token>\"\n}\n)\n\nreturn req.json()"
                      }
                  ]
              }
          ],
          stats: [
              {
                  id: "getInvoiceStats",
                  title: "Get Invoice Stats",
                  content: "This endpoint is used for retrieving invoice statistics using the HTTP method GET.",
                  availableViaAPI: true,
                  url: "/v1/users/stats",
                  method: "GET",
                  parameters: {
                    required: [],
                    optional: []
                  },
                  response: {
                      description: "The API response includes a \"data\" field which contains an array of objects",
                      example: {
                          data: [{
                              date: "string",
                              stats: [{
                                  user: {
                                      avatar: "string",
                                      email: "string",
                                      firstname: "string",
                                      lastname: "string"
                                  },
                                  userStats: {
                                      error: "integer",
                                      inProgress: "integer",
                                      processed: "integer",
                                      uploaded: "integer"
                                  }
                              }]
                          }],
                          error: {
                              code: 0,
                              message: "string"
                          },
                          success: true
                      }
                  },
                  snippets: [
                      {
                          language: "cURL",
                          code: "curl -X GET {host}/v1/users/stats -H \"Authorization: bearer {token}\""
                      },
                      {
                          language: "python",
                          code: "req = requests.get(\n{host}/v1/suppliers/export/{company},\n\
                          headers={\n\"Content-Type\":\"application/json\",\n\"Authorization\": \"bearer <access_token>\"\n}\n)\n\nreturn req.json()"
                      }
                  ]
              }
          ],
        fields: [
              {
                  id: "fieldRequest",
                  title: "Field Request",
                  content: "This endpoint allows a user to request a new field for a specific entity and company.",
                  availableViaAPI: true,
                  contentType: "application/json",
                  url: "/v1/fields/request/{entity}/{company}",
                  method: "POST",
                  parameters: {
                    required: [
                        {
                            name: "entity",
                            type:"string",
                            in: "path",
                            required: true
                        },
                        {
                            name: "company",
                            type:"string",
                            in: "path",
                            required: true
                        },
                        {
                            name: "field",
                            type:"string",
                            in: "body",
                            required: true
                        },
                        {
                            name: "description",
                            type: "string",
                            in: "body",
                            required: true
                        }
                    ],
                    optional: []
                  },
                  response:{
                      description: "The response has a success property that is a boolean and an error property ",
                      example: {
                          success: {
                              type: "boolean"
                          },
                          error: {
                              type: "object",
                              properties: {
                                  code: {
                                      type: "integer"
                                  },
                                  message: {
                                      type: "string"
                                  }
                              }
                          }
                      }
                  },  
                  snippets: [
                      {
                          language: "cURL",
                          code:"curl -X POST {host}/v1/fields/request/{entity}/{company} -H {token} -d \
                          {\"field\": <field_name>, \"description\": <description>}"
                      },
                      {
                          language: "python",
                          code: "payload={'field': '<field>', 'description':'<description>'}\n\
                          req = requests.post(\n{host}/v1/fields/{entity}/{company},\ndata=payload\nheaders={\n\
                              \"Content-Type\":\"application/json\",\n\"Authorization\": \"bearer <access_token>\"\n}\n)\n\nreturn req.json()"
                      }
                  ]
              }
        ],
        customisation: [
            {
                id: "manageCustomisations",
                title: "Manage customisations ",
                availableViaAPI: true,
                content: "",
                url: "/v1/companies/customisations",
                method: "PUT",
                parameters: {
                    required: [
                        {
                            name: "customisation",
                            type: "string",
                            in: "body",
                            required: true,
                            description: "The requested customisation in Procys, it could be one of [tovalidate, toexport...]"
                        },
                        {
                            name: "enabled",
                            type: "boolean",
                            in: "body",
                            required: false,
                            description: "Customisation enabled or disabled"
                        }
                    ],
                    optional: []
                },
                response:{
                    description: "The response includes a boolean \"success\" field, an \"error\" field that contains information about any errors that may have occurred during the request, and a \"data\" field that includes an array of strings representing the fields associated with the specified entity and company.",
                    example: {
                        message: "string",
                        error: {
                            code: "integer",
                            message: "string"
                        },
                        success: "boolean"
                    }
                },  
                snippets: [
                    {
                        language: "cURL",
                        code: "curl -X PUT https://procys-service.procys.com/v1/companies/customisations\n\t-H \"Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.\neyJlbWFpbCI6Impvbi5kb2VAcHJvY3lzLmNvbSIsInVzZXJuYW1lIjoiIiwiYXBwbGljYXRpb24iOiJwcm9jeXMiLCJjb21wYW55IjoiMzIzMjMxMiIsImdyYW50IjoiYWNjZXNzIiwiZXhwIjoxNjgyMTUxODc3fQ.\n-9neUOHETWCS6EuDNGTbNMEqpaoEG4uIPXPv11_Q1UI\"\n\t -d '{\"customisation\":\"tovalidate\",\"enabled\":\"my_password\"}'"
                    },
                    {
                        language: "python",
                        code: "import requests\n\nr = requests.put('https://procys-service.procys.com/v1/companies/customisations',\n\u0020\u0020data={\n\u0020\u0020\u0020\u0020'customisation': 'tovalidate',\n\u0020\u0020\u0020\u0020'enabled': 'true'\n\u0020\u0020},\n\u0020\u0020headers = {\n\u0020\u0020\u0020\u0020\"Content-Type\": \"application/json\",\n\u0020\u0020\u0020\u0020\"Authorization\": \"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.\neyJlbWFpbCI6Impvbi5kb2VAcHJvY3lzLmNvbSIsInVzZXJuYW1lIjoiIiwiYXBwbGljYXRpb24iOiJwcm9jeXMiLCJjb21wYW55IjoiMzIzMjMxMiIsImdyYW50IjoiYWNjZXNzIiwiZXhwIjoxNjgyMTUxODc3fQ.\n-9neUOHETWCS6EuDNGTbNMEqpaoEG4uIPXPv11_Q1UI\"\n\u0020\u0020}\n)\n\nreturn r.json()"
                    },
                    {
                        language: "php",
                        code: "<?php\n\u0020\u0020$url = 'https://procys-service.procys.com/v1/companies/customisations';\n\u0020\u0020$token = \"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.\neyJlbWFpbCI6Impvbi5kb2VAcHJvY3lzLmNvbSIsInVzZXJuYW1lIjoiIiwiYXBwbGljYXRpb24iOiJwcm9jeXMiLCJjb21wYW55IjoiMzIzMjMxMiIsImdyYW50IjoiYWNjZXNzIiwiZXhwIjoxNjgyMTUxODc3fQ.\n-9neUOHETWCS6EuDNGTbNMEqpaoEG4uIPXPv11_Q1UI\";\n\n\u0020\u0020$data = array(\n\u0020\u0020\u0020\u0020\"customisation\" => array(\"tovalidate\"),\n\u0020\u0020\u0020\u0020\"enabled\" => array(\"true\")\n\u0020\u0020);\n\n\u0020\u0020$headers = array(\n\u0020\u0020\u0020\u0020\"Authorization: Bearer $token\",\n\u0020\u0020\u0020\u0020\"Content-Type: application/json\"\n\u0020\u0020);\n\n\u0020\u0020$options = array(\n\u0020\u0020\u0020\u0020'http' => array(\n\u0020\u0020\u0020\u0020\u0020\u0020'header'  => implode(\"\\r\\n\", $headers),\n\u0020\u0020\u0020\u0020\u0020\u0020'method'  => 'PUT',\n\u0020\u0020\u0020\u0020\u0020\u0020'content' => json_encode($data),\n\u0020\u0020\u0020\u0020),\n\u0020\u0020);\n\n\u0020\u0020$context = stream_context_create($options);\n\u0020\u0020$response = file_get_contents($url, false, $context);\n\n\u0020\u0020// The response is in JSON format, decode it to a PHP object \n\u0020\u0020echo $response\n?>"
                    },
                    {
                        language: "C#",
                        code: "using(var httpClient = new HttpClient())\n{\n\u0020\u0020var payload = new Dictionary<string, string>\n\u0020\u0020{\n\u0020\u0020\u0020\u0020{\"customisation\", \"tovalidate\"},\n\u0020\u0020\u0020\u0020{ \"enabled\", \"true\" }\n\u0020\u0020};\n\n\u0020\u0020var requestUrl = \"https://procys-service.procys.com/v1/companies/customisations\";\n\n\u0020\u0020var content = new StringContent(Newtonsoft.Json.JsonConvert.SerializeObject(payload),Encoding.UTF8, \"application/json\");\n\n\u0020\u0020httpClient.DefaultRequestHeaders.Add(\"Authorization\", \"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.\neyJlbWFpbCI6Impvbi5kb2VAcHJvY3lzLmNvbSIsInVzZXJuYW1lIjoiIiwiYXBwbGljYXRpb24iOiJwcm9jeXMiLCJjb21wYW55IjoiMzIzMjMxMiIsImdyYW50IjoiYWNjZXNzIiwiZXhwIjoxNjgyMTUxODc3fQ.\n-9neUOHETWCS6EuDNGTbNMEqpaoEG4uIPXPv11_Q1UI\");\n\n\u0020\u0020var response = httpClient.PutAsync(requestUrl, content).Result;\n\u0020\u0020var responseContent = response.Content.ReadAsStringAsync().Result;\n\n\u0020\u0020Console.WriteLine(responseContent);\n}"
                    }
                ]
            }
        ],
        company: [],
        integrations: []
      }
  }
};

export default root;
