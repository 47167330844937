const styles = {
  container: {
    height: '24px',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '4px',
    border: '1px solid #c44c34',
    flexDirection: 'row',
    margin: '0px 0px 0px 4px',
    padding: '4px',
  },
  typeText: {
    color: '#c44c34',
    fontWeight: 500,
    fontSize: '13px'
  }
};

export default styles;
