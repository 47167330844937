import React from 'react';
import Routes from './Routes';
import { ThemeProvider } from '@mui/material';
import theme from './theme';

function App() {
  return <ThemeProvider theme={theme}>
    <React.Fragment key="routes">
      <Routes />
    </React.Fragment>
  </ThemeProvider>;
}

export default App;
