import theme from "../../../theme";

const styles = {
  container: {
    paddingTop: '10px',
    paddingLeft: '36px',
    paddingBottom: '36px',
    paddingRight: '36px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  contentContainer: {
    width: '50%',
    minWidth: '500px',
    padding: '60px 0px'
  },
  footerContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  navBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    justifyContent: 'space-between',
    margin: '24px 0px'
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  bulletContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: '32px 0px 0px 0px'
  },
  bulletContainerSmall: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: '0px 0px 0px 32px'
  },
  outlinedBulletContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: '12px 0px 0px 32px'
  },
  content: {
    color: theme.palette.text.primary,
    fontSize: '15px',
    marginLeft: '8px',
    fontWeight: 500
  },
  footerText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    marginLeft: '8px',
    fontWeight: 500,
    textAlign: 'justify'
  },
  subContent: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    marginLeft: '5px',
    fontWeight: 500,
  },
  subContentItalic: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    marginLeft: '5px',
    fontWeight: 500,
    fontStyle: 'italic'
  },
  dotIcon: {
    color: theme.palette.text.primary,
    fontSize: '10px',
    marginTop: '6px'
  },
  statusRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  prevBtn: {
    width: '124px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '4px 12px 4px 4px'
  },
  nextBtn: {
    width: '96px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '4px 4px 4px 12px'
  },
  prevIcon: {
    fontSize: '15px',
    fontWeight: 'bold',
    marginRight: '4px'
  },
  nextIcon: {
    fontSize: '15px',
    fontWeight: 'bold',
    marginLeft: '6px'
  }
};

export default styles;
