import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box, Button, Container, Typography, useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material';

import classes from './style';
import Page from '../Page/Page';
import images from '../../../Assets/images';

function Error404() {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page
      title="404: Not found"
      style={classes.root}
    >
      <Container sx={classes.container} maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h3'}
          color="textPrimary"
        >
          404: The page you are looking for isn’t here
        </Typography>
        <Typography
          align="center"
          variant="subtitle1"
          color="textSecondary"
        >
          You either tried some shady route or you
          came here by mistake. Whichever it is, try using the navigation.
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <img
            alt="Under development"
            style={classes.image}
            src={images.pageNotFoundImg}
          />
        </Box>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <Button
            color="primary"
            component={RouterLink}
            to="/"
            variant="outlined"
          >
            Back to home
          </Button>
        </Box>
      </Container>
    </Page>
  );
}

export default Error404;
