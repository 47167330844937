import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import styles from './style';
import Page from '../Page/Page';

const LoadingIndicator = ({ text } : { text: string | undefined }) => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Page title={`${window?.config?.brand} - API`} style={styles.root}>
      <Box width={400} sx={styles.container}>
        {text && (
            <Typography sx={styles.text} align="center">
                {text}
            </Typography>
        )}
        <LinearProgress
          color="primary"
          classes={{ 
            barColorPrimary: styles.barColorPrimary.backgroundColor,
            colorPrimary: styles.colorPrimary.backgroundColor}
          }
        />
      </Box>
    </Page>
  );
};

LoadingIndicator.defaultProps = {
  text: '',
};

LoadingIndicator.propTypes = {
  text: PropTypes.string,
};

export default LoadingIndicator;
