import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { SampleSnippetProps } from '../../../interfaces';
import style from './style';

const SampleSnippet = (props: { sampleCode: SampleSnippetProps[] }) => {
    const { sampleCode } = props;
    const { styles, useStyles } = style;
    const classes = useStyles();
    const [selectedLang, setSelectedLang] = useState(0);
    const [selectedSnippet, setSelectedSnippet] = useState(sampleCode[0]);

    const handleLangChange = (e: SelectChangeEvent<string>) => {
        const newSnippet = sampleCode.find((o) => o.language === e.target.value);
        if (newSnippet) {
            setSelectedLang(sampleCode.findIndex((s) => s.language === e.target.value));
            setSelectedSnippet(newSnippet);
        }
    };

    useEffect(() => {
        setSelectedSnippet(sampleCode[selectedLang] || sampleCode[0]);
    }, sampleCode);

    return (
        <Grid sx={styles.container}>
            <Grid sx={styles.languageContainer}>
                <Typography sx={styles.languageText}>Language: </Typography>
                <Select
                    name="interfacePreference"
                    onChange={handleLangChange}
                    sx={styles.selectStyle}
                    value={selectedSnippet.language}
                    required
                    variant="outlined"
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                        },
                    }}
                >
                    {
                        sampleCode.map((snippet) => (
                            <MenuItem sx={styles.menuItem} key={snippet.language} value={snippet.language}>
                                {snippet.language}
                            </MenuItem>
                        ))
                    }
                </Select>
            </Grid>
            <SyntaxHighlighter
                customStyle={styles.codeStyle}
                language={selectedSnippet.language}
                style={docco}
                wrapLongLines={true}
                codeTagProps={{ className: classes.codeTagStyle }}
            >
                {selectedSnippet.code}
            </SyntaxHighlighter>
        </Grid>
    );
}

export default SampleSnippet;
