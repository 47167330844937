import theme from "../../../theme";

const styles = {
  background: {
    width: '100%',
    paddingTop: '10px',
    paddingLeft: '36px',
    display: { 
      xs: 'none',
      sm: 'flex'
    },
    flexDirection: 'row',
    overflowX: 'hidden'
  },
  backgroundMobile: {
    width: '100%',
    paddingTop: '10px',
    paddingLeft: '36px',
    display: { 
      xs: 'flex',
      sm: 'none'
    },
    flexDirection: 'column',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingRight: '24px',
  },
  methodUrlContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  pageTitle: {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 'bold',
    paddingBottom: '16px'
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 'bold',
    paddingBottom: '16px'
  },
  descr: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    paddingBottom: '24px',
  },
  subTitle: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 'bold'
  },
  pathStyle: {
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0px 8px',
    fontSize: '13px'
  },
  reqBodyContainer: {
    marginTop: '16px',
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  reqBodyTitle: {
    color: theme.palette.text.primary,
    fontSize: '15px',
    fontWeight: 'bold'
  },
  responseTitle: {
    color: theme.palette.text.primary,
    fontSize: '15px',
    fontWeight: 'bold',
    marginBottom: '8px'
  },
  responsesContainer: {
    margin: '10px 0px'
  },
  responsesText: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    paddingBottom: '8px',
  },
  codeContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingRight: '36px',
    maxWidth: {
      xs: '100%',
      sm: '60%'
    },
  },
};

export default styles;
