import React, { Dispatch, SetStateAction } from 'react';
import { Grid, Typography } from '@mui/material';
import Parameter from '../Parameter/Parameter';
import { EndPointProps, ParameterProps } from '../../../interfaces';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import MethodType from '../MethodType/MethodType';
import SampleSnippet from '../SampleSnippet/SampleSnippet';
import ResponseBody from '../ResponseBody/ResponseBody';
import ContentType from '../ContentType/ContentType';
import styles from './style';

const EndpointDetails = (props: { endpoint: EndPointProps, setSelectedTitle: Dispatch<SetStateAction<string>> }) => {
    const { endpoint, setSelectedTitle } = props;

    let requestParameters: ParameterProps[] = [];
    if (endpoint.parameters.required) {
        requestParameters = [...endpoint.parameters.required.filter((o) => o.in !== 'body')];
    }
    if (endpoint.parameters.optional) {
        requestParameters = [...requestParameters, ...endpoint.parameters.optional.filter((o) => o.in !== 'body')];
    }

    let requestBodyParameters: ParameterProps[] = [];
    if (endpoint.parameters.required) {
        requestBodyParameters = [...endpoint.parameters.required.filter((o) => o.in === 'body')];
    }
    if (endpoint.parameters.optional) {
        requestBodyParameters = [...requestBodyParameters, ...endpoint.parameters.optional.filter((o) => o.in === 'body')];
    }

    const textSection = (
        <Grid sx={styles.textContainer}>
            <Typography sx={styles.title}>{endpoint.title}</Typography>
            <Grid sx={styles.methodUrlContainer}>
                <MethodType method={endpoint.method} />
                <SyntaxHighlighter
                    language="cURL"
                    style={docco}
                    useInlineStyles={true}
                    customStyle={styles.pathStyle}
                >
                    {endpoint.url}
                </SyntaxHighlighter>
            </Grid>
            <Typography sx={styles.descr}>{endpoint.content}</Typography>
            {requestParameters.length > 0 && (
                <>
                    <Typography sx={styles.subTitle}>Request Parameters</Typography>
                    {requestParameters.map((o) => <Parameter key={o.name} item={o} setSelectedTitle={setSelectedTitle} />)}
                </>
            )}
            {requestBodyParameters.length > 0 && (
                <>
                    <Grid sx={styles.reqBodyContainer}>
                        <Typography sx={styles.reqBodyTitle}>Request Body</Typography>
                        {endpoint.contentType && <ContentType contentType={endpoint.contentType} />}
                    </Grid>
                    {requestBodyParameters.map((o) => <Parameter key={o.name} item={o} setSelectedTitle={setSelectedTitle} />)}
                </>
            )}
            <Grid sx={styles.responsesContainer}>
                <Typography sx={styles.responseTitle}>Response</Typography>
                <Typography sx={styles.responsesText}>{endpoint.response?.description}</Typography>
            </Grid>
        </Grid>
    );

    const codeSection = (
        <Grid sx={styles.codeContainer}>
            <SampleSnippet sampleCode={endpoint.snippets} />
            {endpoint.response && (<ResponseBody responseBody={{ responseObject: endpoint.response.example }} />)}
        </Grid>
    );

    return (
        <>
            <Grid sx={styles.background}>
                {textSection}
                {codeSection}
            </Grid>
            <Grid sx={styles.backgroundMobile}>
                {textSection}
                {codeSection}
            </Grid>
        </>
    );
}

export default EndpointDetails;
