import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3E8AFF'
    },
    text: {
      primary: '#334D6E',
      secondary: '#464E59',
      disabled: '#A0AEC1'
    },
  },
});

export default theme;