const styles = {
  container: {
    width: '66px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    marginRight: '12px'
  },
  postContainer: {
    backgroundColor: '#b5faa7',
    border: '1px solid #02b019',
  },
  postText: {
    color: '#02b019',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '12px'
  },
  getContainer: {
    backgroundColor: '#5fa8f5',
    border: '1px solid #0256b0',
  },
  getText: {
    color: '#0256b0',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '12px'
  },
  putContainer: {
    backgroundColor: '#f5f05f',
    border: '1px solid #bdb602',
  },
  putText: {
    color: '#bdb602',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '12px'
  },
  deleteContainer: {
    backgroundColor: '#fc7474',
    border: '1px solid #870101',
  },
  deleteText: {
    color: '#870101',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '12px'
  },
  patchContainer: {
    backgroundColor: '#fa84ea',
    border: '1px solid #91037e',
  },
  patchText: {
    color: '#91037e',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '12px'
  }
};

export default styles;
