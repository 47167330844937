import React from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import {
    FiberManualRecord as DotIcon,
    FiberManualRecordOutlined as OutlinedDotIcon,
    ArrowForwardIos as NavigateNextIcon,
    ArrowBackIos as NavigatePrevIcon
  } from '@mui/icons-material';
import { SectionProps } from '../../../interfaces';
import StatusContainer from '../StatusContainer/StatusContainer';
import styles from './style';

const DocumentStatuses = ({ sectionProps }: { sectionProps: SectionProps }) => {
    const { title, setSelectedTitle } = sectionProps;

    const handlePrev = () => {
        setSelectedTitle('authentication');
        window.history.replaceState(null, `${window?.config?.brand} - API`, "/dashboard/authentication");
    };

    const handleNext = () => {
        setSelectedTitle('apiResponses');
        window.history.replaceState(null, `${window?.config?.brand} - API`, "/dashboard/apiResponses");
    };

    return (
        <Grid item md={10} sx={styles.container}>
            <Grid sx={styles.contentContainer}>
                <Typography sx={styles.title}>
                    {title}
                </Typography>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      Each document is subjected to an approval flow and the flow contains
                      specific statuses. The following are the standard document statuses
                      and its corresponding status codes.
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <StatusContainer statusProps={{ status: 'toReview'}} />
                    <Typography sx={styles.subContent}>
                      - 500
                    </Typography>
                </Box>
                {/* <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <StatusContainer statusProps={{ status: 'toValidate'}} />
                    <Typography sx={styles.subContent}>
                      - 501 (* This status is available only if validation flow is enabled)
                    </Typography>
                </Box> */}
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <StatusContainer statusProps={{ status: 'toExport'}} />
                    <Typography sx={styles.subContent}>
                      - 502
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <StatusContainer statusProps={{ status: 'exported'}} />
                    <Typography sx={styles.subContent}>
                      - 503
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <StatusContainer statusProps={{ status: 'rejected'}} />
                    <Typography sx={styles.subContent}>
                      - 510
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <StatusContainer statusProps={{ status: 'archived'}} />
                    <Typography sx={styles.subContent}>
                      - 600
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <StatusContainer statusProps={{ status: 'splitRequest'}} />
                    <Typography sx={styles.subContent}>
                      - 200
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <StatusContainer statusProps={{ status: 'errored'}} />
                    <Typography sx={styles.subContent}>
                      - 100
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      Also note that there could be in between statuses which are primarily used for document processing,
                      that is to understand where the document lies in terms of processing.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainerSmall}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      This could range from 0 to 16.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainerSmall}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      If you encounter a <strong>&quot;status &lt; 100&quot; (specifically &lt;= 16)</strong>,
                      then it means that the document is still being processed.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                        Note that if you encounter a document with <strong>status 200 which is a split request</strong>, 
                        here you have to take an action and decide if you are going to split the document or not. 
                        If you are going to split the document in to multiple sub-documents, then you have to specify 
                        how you are going to split it. For both actions, splitting and not splitting document, 
                        you have to call the split document end-point (<Link href="#">this is a link to split document end-point description</Link>).
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.subContentItalic}>
                        Note that there could be special custom statuses defined for certain companies,
                        contact support (<Link href={`mailto:${window?.config?.support_email}`}>
                            {window?.config?.support_email}</Link>) to know such status information.
                    </Typography>
                </Box>
            </Grid>
            <Box sx={styles.footerContainer}>
                <Typography sx={styles.footerText}>
                    If you encounter any issues or have inquiries about our API, please do not
                    hesitate to reach out to our support team at <Link href={`mailto:${window?.config?.support_email}`}>
                        {window?.config?.support_email}</Link>. Our team
                    is available to assist you in maximizing the benefits of our API and ensuring
                    a seamless integration into your application.
                </Typography>
            </Box>
            <Grid sx={styles.navBtnContainer}>
                <Button
                    variant="contained"
                    sx={styles.prevBtn}
                    color="primary"
                    onClick={handlePrev}
                >
                    <NavigatePrevIcon sx={styles.prevIcon} />
                    Previous
                </Button>
                <Button
                    variant="contained"
                    sx={styles.nextBtn}
                    color="primary"
                    onClick={handleNext}
                >
                    Next
                    <NavigateNextIcon sx={styles.nextIcon} />
                </Button>
            </Grid>
        </Grid>
    );
}

export default DocumentStatuses;
