import makeStyles from "@mui/styles/makeStyles";
import theme from "../../../theme";

const useStyles = makeStyles({
  accordionGroup: {
    margin: '8px 0px 0px !important',
    width: '40%',
    border: '0.5px solid #5c636b !important',
    borderBottomLeftRadius: '12px !important',
    borderBottomRightRadius: '12px !important',
    borderTopLeftRadius: '12px !important',
    borderTopRightRadius: '12px !important',
    "&::before": {
      height: '0px !important'
    },
    backgroundColor: '#e8e8e8',
    boxShadow: 'none !important'
  },
  accordionGroupExpanded: {
    margin: '8px 0px 0px !important',
    width: '100%',
    border: '0.5px solid #5c636b !important',
    borderBottomLeftRadius: '12px !important',
    borderBottomRightRadius: '12px !important',
    borderTopLeftRadius: '12px !important',
    borderTopRightRadius: '12px !important',
  },
  accordianSummary: {
    minHeight: '24px !important',
    border: '0px !important',
    padding: '0px !important'
  },
  accordianSummaryExpanded: {
    minHeight: '24px !important',
    border: '0px !important',
    padding: '0px !important',
  },
  accordianSummaryContent: {
    margin: '0px !important',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px !important'
  }
});

const styles = {
  container: {
    width: '95%',
    borderTop: `1px solid ${theme.palette.text.primary}`,
    margin: '10px 0px',
    padding: '8px 0px 0px 0px'
  },
  paramContainer: {
  },
  propParamContainer: {
    borderBottom: '0.5px solid #5c636b !important',
    margin: '0px',
    padding: '8px 16px'
  },
  propParamContainerLast: {
    margin: '0px',
    padding: '8px 16px'
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  optional: {
    color: '#5c636b',
    fontSize: '11px',
    marginLeft: '4px'
  },
  required: {
    color: '#c44c34',
    fontSize: '12px',
    marginLeft: '4px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  descrContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  descr: {
    color: theme.palette.text.primary,
    fontSize: '12px',
  },
  linkDescr: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    marginLeft: '2px',
    cursor: 'pointer'
  },
  typeContainer: {
    width: '56px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    marginLeft: '6px',
    backgroundColor: '#FFF'
  },
  typeText: {
    color: theme.palette.text.primary,
    fontWeight: 550,
    fontSize: '12px'
  },
  propTypeText: {
    color: theme.palette.text.primary,
    fontWeight: 550,
    fontSize: '11px'
  },
  propTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  propTitleIcon: {
    fontSize: '13px',
    fontWeight: 'bold',
    margin: '0px 4px'
  },
  propTitle: {
    color: theme.palette.text.primary,
    fontSize: '12px',
    fontWeight: 'bold',
  },
};

export default { styles, useStyles };
