import React from 'react';
import { Grid, Typography } from '@mui/material';
import styles from './style';
import { ContentTypeProps } from '../../../interfaces';

const ContentType = (props: ContentTypeProps ) => {
    const { contentType } = props;
    return (
        <Grid sx={styles.container}>
            <Typography sx={styles.typeText}>{contentType}</Typography>
        </Grid>
    );
}

export default ContentType;
