import React from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import {
    FiberManualRecord as DotIcon,
    FiberManualRecordOutlined as OutlinedDotIcon,
    ArrowForwardIos as NavigateNextIcon,
    ArrowBackIos as NavigatePrevIcon
  } from '@mui/icons-material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { SectionProps } from '../../../interfaces';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styles from './style';

const sampleResponse = {
  "success": "boolean",
  "error": {
    "code": "integer",
    "message": "string"
  },
  "<message/data>": "any"
};

const APIResponses = ({ sectionProps }: { sectionProps: SectionProps }) => {
    const { title, selectedEndpoint, setSelectedTitle, handleEndpointSelect } = sectionProps;

    const handlePrev = () => {
        setSelectedTitle('documentStatuses');
        window.history.replaceState(null, `${window?.config?.brand} - API`, "/dashboard/documentStatuses");
    };

    const handleNext = () => {
        setSelectedTitle('procysApi');
        handleEndpointSelect(selectedEndpoint);
    };

    return (
        <Grid item md={10} sx={styles.container}>
            <Grid sx={styles.contentContainer}>
                <Typography sx={styles.title}>
                    {title}
                </Typography>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      The API will respond with standard http status codes. The status codes are 200, 201, 400, 401, 403, 404 and 500.
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.subContent}>
                      A successful response is indicated by either 200 or 201.
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.subContent}>
                      An error message is indicated by 400, 401, 403, 404 and 500.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      The format of the API response will be in the following format:
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <SyntaxHighlighter customStyle={styles.codeStyle} showLineNumbers showInlineLineNumbers language="cURL" style={docco} wrapLongLines={true}>
                      {JSON.stringify(sampleResponse, null, "  ")}
                    </SyntaxHighlighter>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      Here, the &quot;success&quot; boolean indicates that the request was successfully processed or not.
                      If the response is either 200 or 201, then the success will always be true and other statuses, it&apos;ll be false.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      The response also contains an &quot;error&quot; object, it has 2 properties &quot;code&quot; and &quot;message&quot;.
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.subContent}>
                      The &quot;code&quot; represents the http status code.
                    </Typography>
                </Box>
                <Box sx={styles.outlinedBulletContainer}>
                    <OutlinedDotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.subContent}>
                      The &quot;message&quot; contains a message which is developer friendly which can be used to debug or understand what
                      could be the reason for the error. - Please note that this does not contain a stacktrace.
                    </Typography>
                </Box>
                <Box sx={styles.bulletContainer}>
                    <DotIcon sx={styles.dotIcon} />
                    <Typography sx={styles.content}>
                      The other property (sometimes more than one property) of the response is data/information sent as the output
                      of the specific end-point, which is defined in each end-point description.
                    </Typography>
                </Box>
            </Grid>
            <Box sx={styles.footerContainer}>
                <Typography sx={styles.footerText}>
                    If you encounter any issues or have inquiries about our API, please do not
                    hesitate to reach out to our support team at <Link href="mailto:${window?.config?.support_email}">
                      {window?.config?.support_email}
                    </Link>. Our team
                    is available to assist you in maximizing the benefits of our API and ensuring
                    a seamless integration into your application.
                </Typography>
            </Box>
            <Grid sx={styles.navBtnContainer}>
                <Button
                    variant="contained"
                    sx={styles.prevBtn}
                    color="primary"
                    onClick={handlePrev}
                >
                    <NavigatePrevIcon sx={styles.prevIcon} />
                    Previous
                </Button>
                <Button
                    variant="contained"
                    sx={styles.nextBtn}
                    color="primary"
                    onClick={handleNext}
                >
                    Next
                    <NavigateNextIcon sx={styles.nextIcon} />
                </Button>
            </Grid>
        </Grid>
    );
}

export default APIResponses;
