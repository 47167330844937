const styles = {
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100vh',
    paddingTop: '36px',
    paddingBottom: '36px'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px',
    paddingTop: '80px',
    paddingBottom: '80px',
    flexDirection: 'column',
    maxWidth: '100% !important'
  },
  image: {
    maxWidth: '100%',
    width: '560px',
    maxHeight: '300px',
    height: 'auto'
  }
};

export default styles;
