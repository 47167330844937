import React from 'react';
import { Grid, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, Drawer, Box } from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon
  } from '@mui/icons-material';
import { EndPointProps, EndPointSectionProps, LeftPanelProps, RootProps } from '../../../interfaces';
import { endpointSections } from '../../../config';
import style from './style';

const LeftPanel = ({ leftPanelProps }: { leftPanelProps: LeftPanelProps }) => {
    const { styles, useStyles } = style;
    const classes = useStyles();
    
    const { root, mobileOpen, selectedTitle, selectedEndpoint, handleDrawerToggle, setSelectedTitle, handleEndpointSelect } = leftPanelProps;

    const defaultExpanded = Object.keys(root.procysApi.endpoints).find((o) =>
        root.procysApi.endpoints[o as keyof EndPointSectionProps].some((e) => e.id === selectedEndpoint.id))

    const onSectionClick = (title: string) => {
        setSelectedTitle(title);
        window.history.replaceState(null, `${window?.config?.brand} - API`, `/dashboard/${title}`);
    }

    const onProcysApiClick = (title: string) => {
        setSelectedTitle(title);
        window.history.replaceState(null, `${window?.config?.brand} - API`, `/dashboard/${selectedEndpoint.id}`);
    }

    const onEndPointTitleClick = (title: string, endpoint: EndPointProps) => {
        setSelectedTitle(title);
        handleEndpointSelect(endpoint);
    }

    const container = window !== undefined ? () => window.document.body : undefined;

    const drawer = (
        <Box>
            <Divider />
            {Object.keys(root).map((o) => {
                if (o === 'introduction' || o === 'gettingStarted' || o === 'authentication' || o === 'documentStatuses' || o === 'apiResponses') {
                    return (
                        <Typography key={o} sx={selectedTitle === o ? styles.selectedTitle : styles.title} onClick={() => onSectionClick(o)}>
                            {root[o as keyof RootProps].title}
                        </Typography>
                    );
                } else if (o === 'procysApi') {
                    return (
                        <Grid key={o}>
                            <Typography sx={styles.title} onClick={() => onProcysApiClick(o)}>
                                {`${window?.config?.brand} - API`}
                            </Typography>
                            <Grid sx={styles.accordianContainer}>
                                {Object.keys(root.procysApi.endpoints).map((k) => (
                                    root.procysApi.endpoints[k as keyof EndPointSectionProps].some((j) => j.availableViaAPI) &&
                                        <Accordion
                                            key={k}
                                            classes={{ root: classes.accordionGroup, expanded: classes.accordionGroupExpanded }}
                                            defaultExpanded={k === defaultExpanded}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={k}
                                                id={k}
                                                classes={{ 
                                                    root: classes.accordianSummary,
                                                    content: classes.accordianSummaryContent,
                                                    expandIconWrapper: classes.accordianSummaryExpandIcon
                                                }}
                                            >
                                                <Typography sx={styles.endpointSection}>
                                                    {endpointSections[k as keyof EndPointSectionProps]}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails classes={{ root: classes.accordionDetails }}>
                                                <Grid>
                                                    {root.procysApi.endpoints[k as keyof EndPointSectionProps].map((j) => j.availableViaAPI && (
                                                        <Grid key={j.id} sx={styles.endpointNameContainer}>
                                                            <Typography sx={(selectedEndpoint.id === j.id && selectedTitle === 'procysApi') 
                                                                ? styles.selectedEndpointName 
                                                                : styles.endpointName} 
                                                                onClick={() => onEndPointTitleClick(o, j)}
                                                            >
                                                                {j.title}
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                ))}
                            </Grid>
                        </Grid>
                    );
                }
            })}
        </Box>
    );

    return (
        <Box
            component="nav"
            sx={styles.drawerContainer}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={styles.drawerMobile}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={styles.drawer}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

export default LeftPanel;
